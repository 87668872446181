
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  components: { TmFormLine, TmButton, TmModal },
  setup() {
    const name = ref('')
    const list = ref('- Please select a list -')
    const options = [
      'Specific',
      'Marketing',
      'Support',
    ]

    return {
      name,
      options,
      list,
    }
  },
})
